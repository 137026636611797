import PaymentSystem from '@shared/api/payment-system';
const PLATFORM_BALANCE = 250;
const REALTIME_SUMMARY_BALANCE = 5347.53;
const USER_ACTIVITY_MOCK = [
    {
        activityType: 'CLOSE_PORTFOLIO',
        actorId: 2,
        subjectId: '7a5a5714-57c9-47c5-9322-0779fc',
        subjectImage: 'binance',
        timestamp: 1684113618000,
        subjectName: 'Binance Lounge',
    },
    {
        activityType: 'START_FOLLOWING',
        actorId: 2,
        subjectId: '7a5a5714-57c9-47c5-9322-0779fc',
        subjectImage: 'binance',
        timestamp: 1684106418000,
        subjectName: 'Robert Fox',
    },
    {
        activityType: 'CREATE_PORTFOLIO',
        actorId: 2,
        subjectId: '7a5a5714-57c9-47c5-9322-0779fc',
        subjectImage: 'binance',
        timestamp: 1684099218000,
        subjectName: 'Binance Lounge',
    },
    {
        activityType: 'CLOSE_PORTFOLIO',
        actorId: 2,
        subjectId: '7a5a5714-57c9-47c5-9322-0779fc',
        subjectImage: 'binance',
        timestamp: 1684012818400,
        subjectName: 'Binance Main',
    },
    {
        activityType: 'STOP_FOLLOWING',
        actorId: 2,
        subjectId: '7a5a5714-57c9-47c5-9322-0779fc',
        subjectImage: 'binance',
        timestamp: 1684011918000,
        subjectName: 'Marry Monro',
    },
    {
        activityType: 'CLOSE_PORTFOLIO',
        actorId: 2,
        subjectId: '7a5a5714-57c9-47c5-9322-0779fc',
        subjectImage: 'binance',
        timestamp: 1684011738000,
        subjectName: 'Binance Lounge',
    },
    {
        activityType: 'CLOSE_PORTFOLIO',
        actorId: 4,
        subjectId: '7a5a5714-57c9-47c5-9322-0779fc',
        subjectImage: 'binance',
        timestamp: 1684012812000,
        subjectName: 'Binance Main',
    },
];
const TRANSACTION_HISTORY_MOCK = [
    {
        id: 'tes',
        date: 'Fri Jan 12 2024 08:39:29',
        type: 'REFUND',
        status: 'SUCCESS',
        amount: 50,
        portfolio: {
            id: 'e50ac96f-2720-48a6-8d2d-4d1f6asd8361',
            portfolioName: 'Test',
            profileId: '8cfdb100-47e8-4b93-9f9f-6f3c9db6ed7c',
        },
        details: {
            paymentSystem: PaymentSystem.Visa,
            cardNumber: 'VISA****9087',
            platformFee: 100,
            initialAmount: 500,
            orderId: 34354548758656
        }
    },
    {
        id: 'tes2',
        date: 'Sat Jan 13 2024 08:39:29',
        type: 'SUBSCRIPTION',
        status: 'ERROR',
        amount: -50,
        portfolio: {
            id: 'e50ac96f-2720-48a6-8d2d-4d1fasf361',
            portfolioName: 'Test',
            profileId: '8cfdb100-47e8-4b93-9f9f-6f3c9db6ed7c',
        },
        details: {
            paymentSystem: PaymentSystem.Visa,
            cardNumber: 'VISA****9087',
            platformFee: -100,
            initialAmount: 500,
            orderId: 34354548758356
        }
    }
];
export { PLATFORM_BALANCE, REALTIME_SUMMARY_BALANCE, USER_ACTIVITY_MOCK, TRANSACTION_HISTORY_MOCK, };
