import restApiUnauthorized from '@app/store/api/restApiUnauthorized';
const authApi = restApiUnauthorized.injectEndpoints({
    endpoints: (builder) => ({
        register: builder.mutation({
            query: (requestBody) => ({
                url: '/api/v1/auth/register',
                method: 'POST',
                body: requestBody,
                headers: {
                    'Content-Type': 'application/json',
                },
            }),
        }),
        verifyEmail: builder.mutation({
            query: ({ verificationToken }) => ({
                url: '/api/v1/auth/verify',
                method: 'POST',
                body: {
                    verificationToken,
                },
                headers: {
                    'Content-Type': 'application/json',
                },
            }),
        }),
        getEmailVerificationLink: builder.query({
            query: ({ email }) => ({
                url: `/api/v1/auth/verify?email=${email}`,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            }),
        }),
        getResetPasswordLink: builder.query({
            query: ({ email }) => ({
                url: `/api/v1/auth/password/recovery?email=${email}`,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            }),
        }),
        changePassword: builder.mutation({
            query: (body) => ({
                url: '/api/v1/auth/password/recovery',
                method: 'POST',
                body,
                headers: {
                    'Content-Type': 'application/json',
                },
            }),
        }),
        login: builder.mutation({
            query: (requestBody) => ({
                url: '/api/v1/auth/login',
                method: 'POST',
                body: requestBody,
                headers: {
                    'Content-Type': 'application/json',
                },
            }),
        }),
        refreshToken: builder.mutation({
            query: ({ refreshToken }) => ({
                url: '/api/v1/auth/refresh',
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${refreshToken}`,
                    'Content-Type': 'application/json',
                },
            }),
        }),
    }),
});
export default authApi;
export const { useRegisterMutation, useLoginMutation, useVerifyEmailMutation, useLazyGetResetPasswordLinkQuery, useChangePasswordMutation, useLazyGetEmailVerificationLinkQuery, } = authApi;
