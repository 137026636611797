var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import restApiAuthorized from '@app/store/api/restApiAuthorized';
const portfoliosApi = restApiAuthorized.injectEndpoints({
    endpoints: (builder) => ({
        createPortfolio: builder.mutation({
            query: ({ portfolioName, profileId, subscriptionPrice, walletId, }) => ({
                url: '/api/v1/portfolio',
                body: {
                    portfolioName,
                    profileId,
                    subscriptionPrice,
                    walletId,
                },
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            }),
            invalidatesTags: ['CurrentUserPortfolios'],
        }),
        updatePortfolio: builder.mutation({
            query: (_a) => {
                var { portfolioId } = _a, optionalParams = __rest(_a, ["portfolioId"]);
                return ({
                    url: `/api/v1/portfolio/${portfolioId}`,
                    body: Object.assign({}, optionalParams),
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
            },
            invalidatesTags: ['CurrentUserPortfolios'],
        }),
        deletePortfolio: builder.mutation({
            query: ({ id }) => ({
                url: `/api/v1/portfolio/${id}`,
                method: 'DELETE',
            }),
        }),
        getPopularList: builder.query({
            query: () => ({
                url: '',
                body: {},
                method: 'GET',
            }),
            providesTags: ['PopularPortfolios'],
        }),
        getPortfolio: builder.query({
            query: ({ portfolioId }) => ({
                url: `/api/v1/portfolio/${portfolioId}`,
                method: 'GET',
            }),
            providesTags: (result, error, { portfolioId }) => [{ type: 'Portfolios', id: portfolioId }],
        }),
        getProfilePortfolios: builder.query({
            query: ({ profileId, assessmentPassed }) => ({
                url: `/api/v1/profile/${profileId}/portfolio`,
                method: 'GET',
                params: typeof assessmentPassed === 'boolean' ?
                    { assessmentPassed } : undefined
            }),
            providesTags: (result) => [
                ...(result || []).map(p => ({ type: 'Portfolios', id: p.id })),
                'Portfolios',
            ],
        }),
        getCurrentProfilePortfolios: builder
            .query({
            query: ({ profileId, assessmentPassed }) => ({
                url: `/api/v1/profile/${profileId}/portfolio`,
                method: 'GET',
                params: typeof assessmentPassed === 'boolean' ?
                    { assessmentPassed } : undefined
            }),
            providesTags: (result) => (result ?
                [...result.map(p => ({ type: 'CurrentUserPortfolios', id: p.id })), 'CurrentUserPortfolios'] :
                ['CurrentUserPortfolios']),
        }),
        getCurrentProfilePortfolioSubscriptions: builder
            .query({
            query: ({ profileId }) => ({
                url: `/api/v1/profile/${profileId}/portfolio_subscriptions`,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            }),
            providesTags: (res, err, { profileId }) => [{ type: 'PortfolioSubscriptions', id: profileId }]
        }),
        getPortfolioCopiers: builder.query({
            query: ({ portfolioId, skip, take }) => ({
                url: '/api/v1/auth/me',
                // body: {},
                method: 'GET',
            }),
            providesTags: (result) => (result ?
                [
                    ...result.map(c => ({ type: 'PortfolioCopiers', id: c.id })),
                    'PortfolioCopiers',
                ] :
                ['PortfolioCopiers']),
            // eslint-disable-next-line max-len
            transformResponse: (response, meta, { skip, take, sortByDelay }) => [],
        }),
        getPortfolioCopiersQueueDetails: builder
            .query({
            query: ({ portfolioId }) => ({
                url: '/api/v1/auth/me',
                // body: {},
                method: 'GET',
            }),
            // eslint-disable-next-line max-len
            // TODO-FIX: would be fixed when portfolio copiers would be available
            // transformResponse: () => getCopiersQueueDetails()
            // as unknown as PortfolioCopiersQueueDetails,
            transformResponse: () => ({
                first: undefined,
                last: undefined,
                copiers_count: 0,
                delay_step: 0.01,
                projected_income: {
                    total: 0,
                    step: 0,
                },
                avg_price_per_position: 0,
            }),
        }),
        startCopying: builder.mutation({
            query: ({ portfolioId }) => ({
                url: '',
                body: {},
                method: 'POST',
            }),
            invalidatesTags: ['PortfolioCopiers'],
        }),
        getPortfolioCurrentPositions: builder
            .query({
            query: ({ portfolioId, skip, take, sortByPnl, }) => ({
                url: '/api/v1/auth/me',
                // body: {},
                method: 'GET',
            }),
            // eslint-disable-next-line max-len
            // TODO-FIX: would be fixed when positions would be available
            // transformResponse: (response, meta, { skip, take, sortByPnl }) =>
            // getCurrentPositions(
            // skip, take, sortByPnl
            // ) as unknown as GetPortfolioPositionsResponse,
            transformResponse: () => ({
                data: [],
                count: 0,
            }),
            // TODO-FIX: should be updated\removed after enpoint clarification
            providesTags: (res, err, args) => [{ type: 'PortfolioPositions', id: args.portfolioId }],
        }),
        getPortfolioPositionsHistory: builder
            .query({
            query: ({ portfolioId, skip, take, }) => ({
                url: '/api/v1/auth/me',
                // body: {},
                method: 'GET',
            }),
            // eslint-disable-next-line max-len
            // TODO-FIX: would be fixed when positions would be available
            // transformResponse: (response, meta, { skip, take }) =>
            //  getPositionsHistory(skip, take) as unknown as GetPortfolioPositionsHistoryResponse,
            transformResponse: () => ({
                data: [],
                count: 0,
            }),
        }),
    }),
});
export default portfoliosApi;
export const { useCreatePortfolioMutation, useGetPopularListQuery, useLazyGetPopularListQuery, useGetPortfolioCopiersQuery, useGetPortfolioCopiersQueueDetailsQuery, useStartCopyingMutation, useGetPortfolioCurrentPositionsQuery, useGetPortfolioPositionsHistoryQuery, useGetPortfolioQuery, useGetProfilePortfoliosQuery, useLazyGetPortfolioQuery, useDeletePortfolioMutation, useUpdatePortfolioMutation, useGetCurrentProfilePortfoliosQuery, useGetCurrentProfilePortfolioSubscriptionsQuery, useLazyGetCurrentProfilePortfoliosQuery, useLazyGetCurrentProfilePortfolioSubscriptionsQuery, } = portfoliosApi;
